<template>
  <section>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label-size="lg"
            label="Selecione um serviço"
            label-for="service_id"
          >
            <b-form-select
              v-model="service_id"
              :state="service_id === null ? false : true"
              :options="listOfActions"
              @change="(val) => updateMinMax(val)"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                >Por favor, selecione um serviço!</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="mt-1"
        >
          <b-form-group
            label-size="lg"
            label="Coloque os seus links (Você pode definir mais de um link por linha)"
            label-for="service_id"
          >
            <b-form-textarea
              v-model="links"
              placeholder=""
              rows="1"
              max-rows="8"
              no-auto-shrink
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label-size="lg"
            label="Quantidade"
            label-for="service_id"
          >
            <Quantity
              v-model="quantity"
              :disabled="service_id === null || links.length === 0"
              :min="min"
              :max="max"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-if="calculated === false"
            variant="relief-primary"
            block
            :disabled="
              service_id === null || links.length === 0 || !checkMinMax
            "
            @click.stop="calculate()"
          >
            Calcular
          </b-button>

          <b-alert
            v-if="calculated"
            variant="success"
            show
          >
            <h4 class="alert-heading text-center">
              Valor total do pedido: R$ {{ cost | currency }}
            </h4>
          </b-alert>

          <b-button
            v-if="calculated"
            variant="relief-primary"
            block
            :disabled="
              service_id === null || links.length === 0 || !checkMinMax
            "
            @click.stop="finish()"
          >
            Finalizar
          </b-button>
        </b-col>

        <b-col
          cols="12"
          class="mt-1"
        >
          <b-alert
            v-if="calculated"
            variant="dark"
            show
          >
            <h4 class="alert-heading text-center">
              Essa ação não pode ser cancelada.
            </h4>
          </b-alert>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormSelect,
  BFormSelectOption,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAlert
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import currencyPrecision from '@/libs/currencyPrecision'
import Quantity from './Quantity.vue'
import SwalMessages from '../BuyServices/utils/SwalMessages'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormSelect,
    BFormSelectOption,
    BFormGroup,
    BFormTextarea,
    Quantity,
    BButton,
    BAlert
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 6)
    }
  },
  data: () => ({
    service_id: null,
    links: '',
    min: 1,
    max: 1,
    quantity: 1,
    cost: 0,
    snActions: [],
    calculated: false,
    order: ''
  }),
  computed: {
    ...mapState('discountLevel', ['level']),
    listOfActions () {
      return this.snActions.map(item => {
        return {
          value: item.id,
          html: `${item.name} [ID: ${item.id}] - ${currencyPrecision(
            item.value * 1000,
            2,
            2
          )} por 1000`
        }
      })
    },
    hasBenefit () {
      const benefits = Object.values(this.level.benefit_applied)
      return benefits.includes(2)
    },
    discountLevel () {
      return this.level
    },
    checkMinMax () {
      return this.quantity >= this.min && this.quantity <= this.max
    }
  },
  watch: {
    service_id () { this.calculated = false },
    quantity () { this.calculated = false },
    links () { this.calculated = false }
  },
  async mounted () {
    await this.getListOfActions()
  },
  methods: {
    async getListOfActions () {
      await this.$http
        .get('/user/services/sn_actions/list_of_actions')
        .then(res => {
          this.snActions = res.data
        })
    },
    updateMinMax (id) {
      const selected = this.snActions.find(item => item.id === id)
      this.min = selected.min
      this.max = selected.max

      this.quantity = parseInt(this.min)
    },
    calcDiscount (value) {
      if (this.hasBenefit) {
        return parseFloat(value - (value / 100) * this.level.discount)
      }

      return value
    },
    calculate () {
      const selected = this.snActions.find(
        item => item.id === this.service_id
      )
      const links = this.links.split('\n')
      let custom = ''
      const linksQuantity = this.links
        .split('\n')
        .filter(item => item.length > 0).length

      for (const link of links) {
        custom += `${this.service_id}|${link}|${this.quantity}\n`
      }

      const cost = linksQuantity * this.quantity * selected.value
      this.cost = this.calcDiscount(cost)
      this.order = custom
      this.calculated = true
    },
    async finish () {
      const url = '/user/services/sn_actions/purchase/bulk'
      const params = {
        order_bulk: this.order
      }

      await this.$http
        .post(url, params)
        .then(res => {
          if (
            (res.data.message === 'your order has been added to the queue!'
              || res.data.message === 'successfully created.')
            && res.status === 201
          ) { this.success() }
          if (res.data.message === 'no balance.') this.noBalance()
          if (res.data.message === 'Something went wrong.') this.simpleError()
        })
        .finally(() => {
          this.loading = false
        })
    },
    success () {
      this.$swal(SwalMessages.order_bulk_success)
        .then(buttons => (buttons.isConfirmed
          ? this.$router.push({ name: 'Meus Seguidores' })
          : null))
    },
    noBalance () {
      this.$swal(SwalMessages.no_balance).then(buttons => (buttons.isConfirmed
        ? this.$router.push({ name: 'Adicionar Saldo' })
        : null))
    },
    linkDuplicated () {
      this.$swal(SwalMessages.order_unique_link_duplicated)
    },
    simpleError () {
      this.$swal(SwalMessages.swal_simple_error)
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
